<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Acasă',
    items: [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
      { label: 'Medici', icon: 'pi pi-user', to: '/doctors' },
      { label: 'Pacienți', icon: 'pi pi-book', to: '/patients' },
      { label: 'Decontări', icon: 'pi pi-dollar', to: '/payouts' },
      { label: 'Înrolări', icon: 'pi pi-user-plus', to: '/requests' },
      { label: 'Configurări', icon: 'pi pi-cog', to: '/config' },
      { label: 'Rapoarte', icon: 'pi pi-chart-bar', to: '/reports' }
    ]
  }
]);

const isMobile = ref(window.innerWidth <= 992);

const updateMenu = () => {
  const logoutItem = { label: 'Logout', icon: 'pi pi-sign-out' };
  const menuItems = model.value[0].items;

  if (isMobile.value) {
    if (!menuItems.find((item) => item.label === 'Logout')) {
      menuItems.push(logoutItem);
    }
  } else {
    const index = menuItems.findIndex((item) => item.label === 'Logout');
    if (index !== -1) {
      menuItems.splice(index, 1);
    }
  }
};

watch(isMobile, updateMenu);

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  updateMenu();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item.label">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
