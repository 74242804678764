<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';

import KeyCloakService from '../api/KeyCloakService';
import AdminInfoService from '../services/AdminInfoService';

const { onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const adminInfo = ref(null);

onMounted(async () => {
  bindOutsideClickListener();
  await fetchAdminInfo();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const logoUrl = computed(() => {
  return `logo.png`;
});

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};

const onSettingsClick = () => {
  topbarMenuActive.value = false;
  KeyCloakService.CallLogOut();
  location.reload();
};

const topbarMenuClasses = computed(() => {
  return {
    'layout-topbar-menu-mobile-active': topbarMenuActive.value
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector('.layout-topbar-menu');
  const topbarEl = document.querySelector('.layout-topbar-menu-button');

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const fetchAdminInfo = async () => {
  const adminInfoService = new AdminInfoService();
  try {
    adminInfo.value = await adminInfoService.fetchAdminInfo();
  } catch (error) {
    console.error('Failed to fetch admin info:', error);
  }
};
</script>

<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img :src="logoUrl" alt="logo" />
      <span>DoctorApp</span>
    </router-link>

    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button visibility-hidden"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div
      class="layout-topbar-menu gap-4 align-items-center"
      :class="topbarMenuClasses"
    >
      <div v-if="adminInfo" class="admin-info layout-topbar-menu">
        <span>{{ adminInfo.phone }}</span>
      </div>
      <button
        @click="onSettingsClick()"
        class="p-link layout-topbar-button gap-2"
      >
        <span class="block font-semibold">Logout</span>
        <i class="pi pi-sign-out"></i>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 992px) {
  .visibility-hidden {
    visibility: hidden;
  }
}
</style>
