import { AppConstants } from './AppConstants';
import Keycloak from 'keycloak-js';

export class AppContext {
  static getApiUrlPath = (): string => {
    switch (AppConstants.ENVIRONMENT) {
      case 'local':
        return AppConstants.API_BASE_URL_LOCAL;
      case 'test':
        return AppConstants.API_BASE_URL_TEST;
      case 'prod':
        return AppConstants.API_BASE_URL_PROD;
      default:
        throw new Error('Invalid environment defined');
    }
  };
  static getKeycloak = (): Keycloak => {
    return new Keycloak(AppContext.getKeyCloackJson());
  };

  static getBaseUrl() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      return 'http://localhost:8081';
    } else if (hostname.includes('test.doctorapp')) {
      return 'https://test.doctorapp.ro';
    } else if (hostname.includes('register.doctorapp')) {
      return 'https://register.doctorapp.ro';
    } else {
      return 'https://admin.doctorapp.ro';
    }
  }
  static adjustContextPath() {
    const hostname = window.location.toString();
    //alert(hostname);
    if (hostname.includes('localhost')) {
      return '/admin';
    }
    if (
      hostname.includes('/admin') &&
      !hostname.includes('admin.doctorapp.ro')
    ) {
      return '/admin';
    }
    return '';
  }
  static getKeyCloackJson() {
    const host =
      AppContext.getBaseUrl() +
      AppContext.adjustContextPath() +
      '/keycloak.json';

    return host;
  }
}
