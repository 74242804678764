import BackendAPI from '@/api/BackendAPI';
import { Admin } from '@/interfaces/Admin';

export default class AdminInfoService extends BackendAPI {
  constructor() {
    super();
  }

  public async fetchAdminInfo(): Promise<Admin> {
    const endpoint = '/userInfo';
    const response = await this.doCallSecured(endpoint, 'GET');
    return await response.json();
  }
}
